import styles from "./styles.module.scss";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import themedev from "/public/home/themeDev/themedev.png";
import themedev_mobile from "/public/home/themeDev/themeDev-mobile.jpg";
import {Button, ButtonGroup, Tooltip} from "@shopify/polaris";
import {StoreMajor, ThemesMajor} from "@shopify/polaris-icons";
import {COMMON_NAMESPACE, HOME_NAMESPACE} from "../../../../utils/i18nNameSpaces";


const ThemeDev = () => {
  const {t} = useTranslation(HOME_NAMESPACE);

  return (
    <section className={styles["appdev"]}>
      <div className={styles["appdev__container"]}>
        <div className={styles["appdev__img-wrapper"]}>
          <div className={styles["appdev__img--desc"]}>
            <Image
              src={themedev}
              quality={100}
              layout="responsive"
              alt="Man is programming"
            />
          </div>
          <div className={styles["appdev__img--mobile"]}>
            <Image
              src={themedev_mobile}
              quality={100}
              layout="responsive"
              alt="Man is programming"
            />
          </div>
        </div>
        <section className={styles["appdev__content"]}>
          <header className={styles["appdev__header-wrapper"]}>
            <h3 className={styles["appdev__header"]}>{t("themedev.header")}</h3>
            <p className={styles["appdev__subheader"]}>{t("themedev.subheader")}</p>
          </header>
          <section className={styles["appdev__info"]}>
            <span className={styles["appdev__content-label"]}>2</span>
            <header>
              <h4 className={styles["appdev__content-header"]}>{t("themedev.content.header")}</h4>
              <p className={styles["appdev__content-subheader"]}>{t("themedev.content.subheader")}</p>
            </header>
            <footer className={styles["appdev__content-footer"]}>
              <div className={styles["appdev__reviews"]}>
                <span className={styles["appdev__info-value"]}>94%</span>
                <span className={styles["appdev__info-text"]}>{t("themedev.content.reviews")}</span>
              </div>
              <div className={styles["appdev__stores"]}>
                <p className={styles["appdev__info-text"]}>
                  <span className={styles["appdev__info-value"]}>116</span>
                  {t("themedev.content.stores")}</p>
              </div>
            </footer>
            <ButtonGroup>
              <Tooltip content={t("header:theme-tooltip")} active={false}>
                <span className={"tooltip-wrapper"}>
                  <Button icon={StoreMajor} external disabled>{t("themedev.themePage")}</Button>
                </span>
              </Tooltip>
              <Tooltip content={t("header:theme-tooltip")} active={false}>
                <span className={"tooltip-wrapper"}>
                  <Button icon={ThemesMajor} external disabled>{t("themedev.allThemePage")}</Button>
                </span>
              </Tooltip>
            </ButtonGroup>
          </section>
        </section>
      </div>
    </section>
  );
};

export default ThemeDev;